import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero-all';
import Footer from '../components/Footer';

const HandyPage = () => {
  const websites = [
    {
      title: 'Mac OS X Setup Guide',
      link: 'http://sourabhbajaj.com/mac-setup/',
      description: 'Get any new Mac set up to a devs standards',
      topics: 'Initial setup',
    },
    {
      title: 'CSS Tricks',
      link: 'https://css-tricks.com',
      description: 'The best info for all things CSS',
      topics: 'CSS',
    },
    {
      title: 'CSS Arrow Please',
      link: 'http://www.cssarrowplease.com/',
      description: 'CSS code for custom arrow box',
      topics: 'CSS',
    },
    {
      title: 'Tailwind',
      link: 'https://tailwindcss.com/',
      description:
        'A utility-first CSS framework I use and used to build this site',
      topics: 'CSS',
    },
    {
      title: 'Replit',
      link: 'https://replit.com/',
      description: 'the collaborative browser based IDE with 50+ languages',
      topics: 'Brainstorming',
    },
    {
      title: 'Da Fonts',
      link: 'https://www.dafont.com/',
      description: 'Great free fonts for downloads',
      topics: 'Fonts',
    },
    {
      title: 'Time converter',
      link: 'https://www.epochconverter.com/',
      description: 'Convert human time to computer time',
      topics: 'JS',
    },
    {
      title: 'React Cheatsheet',
      link: 'https://reactcheatsheet.com/',
      description: 'All things React',
      topics: 'JS',
    },
    {
      title: 'HTML Entity Cheatsheet',
      link: 'https://brajeshwar.github.io/entities/',
      description: 'Helpful for coding HTML entities',
      topics: 'HTML',
    },
    {
      title: 'Slack Emoji Library',
      link: 'https://slackmojis.com/',
      description: 'Get an emotion for every emotion on slack',
      topics: 'Slack',
    },
  ];

  return (
    <Layout>
      <div>
        <Hero image="/img/flatlay.jpeg" title="&lowbar; is handy." />
      </div>
      <section className="container max-w-4xl mx-auto px-4">
        <div>
          <div className="mb-2">
            <h2 className="font-headline">Things I find useful</h2>
          </div>
          <section className="my-4">
            <h3 className="mb-2">Websites</h3>
            <p className="mb-4">
              I have found many helpful tools on my journey, and I want to share
              them with you. Some are sites I use or have used to aid me, while
              others are recommended reading that have helped me grow.
              Regardless of the form they take, each resource has played a role
              in shaping my skills and expanding my knowledge. I hope you find
              them useful as well.
            </p>
            <table className="table-auto">
              <thead>
                <tr className="border-b border-gray-300">
                  <th>
                    <strong>Link</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Topics</strong>
                  </th>
                </tr>
              </thead>

              <tbody>
                {websites.map((item) => (
                  <tr className="border-b border-gray-300" key={item.link}>
                    <td className="p-3">
                      <a
                        className="has-text-weight-bold"
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.title}
                      </a>
                    </td>
                    <td className="p-3">{item.description}</td>
                    <td className="p-3">{item.topics}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <section className="mt-8">
            <h3 className="mb-2">Quotes</h3>
            <p className="mb-4">
              These quotes have helped me navigate both good and bad times, and
              I hope they can offer you some guidance and motivation as well.
              Please feel free to explore and reflect on them at your leisure.
            </p>

            <em>Coming soon...</em>
          </section>
        </div>
        <Footer />
      </section>
    </Layout>
  );
};

export default HandyPage;
