import React from 'react';

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer w-full flex justify-center mt-7 mb-10">
        <div className="content text-center">
          <p className="text-sm align-baseline">
            &copy; {new Date().getFullYear()} R.A.F.&hearts;
          </p>
          <p className="text-xs">All rights reserved.</p>
        </div>
      </footer>
    );
  }
}
